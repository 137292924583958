import { SyncOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Checkbox, message, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../components/SN/SNForm/SNInput";
import SNTitle from "../../../../../components/SN/SNTitle";
import { createUser } from "../../../service";  // Update this import
import { useHistory } from "react-router-dom";

interface AccountTemplateFormProps {
  form: FormInstance;
  editData?: any;
}

function AccountTemplateForm(props: AccountTemplateFormProps) {
  const { form } = props;
  const history = useHistory();
  const [fetchingPassword, setFetchingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [_, setSubmitting] = useState(false);

  useEffect(() => {
    _getPassword(false);
  }, []);

  const generatePassword = () => {
    // Generate a random password with letters, numbers and special characters
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i < passwordLength; i++) {
      password += chars[Math.floor(Math.random() * chars.length)];
    }
    return password;
  };

  const _getPassword = (showSuccess: boolean = true) => {
    setFetchingPassword(true);
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword });

    setTimeout(() => {
      setFetchingPassword(false);
      showSuccess &&
        message.open({
          type: "success",
          content: "Newly generated password",
          duration: 1,
        });
    }, 1000);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      const response = await createUser(values);  // Now using imported createUser directly
      if (response.status === 201) {
        message.success('User created successfully');
        history.push('/accounts/all-accounts'); // Redirect to accounts list
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        const errorData = error.response.data;
        // Handle validation errors from API
        Object.keys(errorData).forEach(key => {
          form.setFields([
            {
              name: key,
              errors: [errorData[key]],
            },
          ]);
        });
      } else {
        message.error('Failed to create user. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const renderAccountInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info"]} title="Account information" />

        <SNFormItem
          label="Email"
          name="username"
          required
          rules={[{ type: "email", message: "Not a valid email" }]}
        >
          <SNInput type="email" placeholder="Enter email for this user" />
        </SNFormItem>
        <SNFormItem label="First name" name="first_name" required>
          <SNInput maxLength={50} placeholder="Enter first name" />
        </SNFormItem>
        <SNFormItem label="Last name" name="last_name">
          <SNInput maxLength={50} placeholder="Enter last name" />
        </SNFormItem>

        <SNFormItem label="Password" name="password" required>
          <SNInput
            maxLength={20}
            minLength={8}
            addonAfter={
              <>
                <SyncOutlined
                  spin={fetchingPassword}
                  className="sn-btn-hoverable"
                  onClick={() => _getPassword()}
                />
                {showPassword ? (
                  <EyeInvisibleOutlined className="sn-btn-hoverable" onClick={togglePasswordVisibility} style={{ marginLeft: 8 }} />
                ) : (
                  <EyeOutlined className="sn-btn-hoverable" onClick={togglePasswordVisibility} style={{ marginLeft: 8 }} />
                )}
              </>
            }
            type={showPassword ? "text" : "password"}
            placeholder="Enter password for this user"
          />
        </SNFormItem>

        <SNFormItem label="Language" name="language" initialValue="sv" required> {/* Set default to Swedish */}
          <Select placeholder="Select language">
            <Select.Option value="en">English</Select.Option>
            <Select.Option value="es">Spanish</Select.Option>
            <Select.Option value="fr">French</Select.Option>
            <Select.Option value="de">German</Select.Option>
            <Select.Option value="zh">Chinese</Select.Option>
            <Select.Option value="sv">Swedish</Select.Option>
            <Select.Option value="it">Italian</Select.Option>
            <Select.Option value="nl">Dutch</Select.Option>
            <Select.Option value="pt">Portuguese</Select.Option>
            <Select.Option value="pl">Polish</Select.Option>
          </Select>
        </SNFormItem>

        <SNFormItem name="is_active" valuePropName="checked">
          <Checkbox>Active this account after created ?</Checkbox>
        </SNFormItem>
        <SNFormItem name="send_login" valuePropName="checked">
          <Checkbox>Send login infomation to user ?</Checkbox>
        </SNFormItem>
      </section>
    );
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {renderAccountInfo()}
    </Form>
  );
}

export default AccountTemplateForm;
